import React, {useContext, createElement, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom/client';

import { appContext } from "../App";

import { FaSearch } from "react-icons/fa";

import Navbar from "../components/Navbar";
import LogoutWarning from "../components/LogoutWarning";

const PickResidents = () =>{
    const navigate = useNavigate();
    const client = useContext(appContext).client;
    const clientMethods = useContext(appContext).clientMethods;
    const currRes = useContext(appContext).currRes;

    const [logPopup, setlogPopup] = useState(false);
    
    useEffect(() => {
        if(client.ticket === 'null')
            clientMethods.setInfoFromClient(sessionStorage.getItem("client"))
        
        if (client.resCount === 0 || client.resCount > 5){
            searchBar();
        } else{
            noSearchBar(JSON.parse(client.availRes));
        }
    });

    function searchBar() {
        document.getElementById('searchBarContainer').style.display = 'flex';
    }

    async function searchBtnClk(srch){
        let srch_tr = srch.trim()
        if (srch_tr.length === 0)
        {
            //TODO: HANDLE EMPTY SEARCH STRING
        } else{
            const data = await clientMethods.residentSearch(srch)
            let pts2 = JSON.parse(data);
            const container = document.getElementById('residentResultContainer');
            const root = ReactDOM.createRoot(container);
            let nodeArray=[]
            for(let i = 0; i < pts2.length; i++){
                let newNode = addResidentCard(pts2[i], i);
                nodeArray.push(newNode);
            }
            root.render(nodeArray)
        }
    }

    function noSearchBar(pts){
        document.getElementById('searchBarContainer').style.display = 'none';
        const container = document.getElementById('residentResultContainer');
        const root = ReactDOM.createRoot(container);
        let nodeArray=[]
        for(let i = 0; i < pts.length; i++){
            let newNode = addResidentCard(pts[i], i);
            nodeArray.push(newNode);
        }
        root.render(nodeArray)
    }

    function addResidentCard(pt, num){
        let name = pt.FirstName;
        if(pt.NickName != null){
            name += " '" + pt.NickName + "'";
        }
        name += " " + pt.LastName;
        
        let id = pt.PatientId;
        let org = pt.OrgId;
        let pic = clientMethods.getPatientPicture(id);

        return createElement(
        'div',
        {className:'resCont'},
        createElement(
            'div',
            {   className:'residentContainer',
                id:'result'+num,
                onClick: async () => {
                    client.currResId = id;

                    let data = await clientMethods.getPatientInfo(client.currResId)
                    currRes.resFromString(data)

                    data = await clientMethods.getPatientPastInteraction(client.currResId)
                    currRes.actionsFromString(data)

                    sessionStorage.setItem('client', JSON.stringify(client))
                    sessionStorage.setItem('currRes', JSON.stringify(currRes))
                    navigate("/user")
                } 
            },
            createElement(
                'div',
                {className:'resContLeft'},
                createElement(
                    'img',
                    {   className:'resPic',
                        width:'75px',
                        height:'75px',
                        alt:'prop_pic',
                        src: pic
                    }
                )
            ), 
            createElement(
                'div',
                {className:'resContRight'},
                    <div className="infoContainer">
                        <p className="infoTag" id="resName' + num + '">{name}</p>
                        <p className="infoTag" id="resId' + num + '">ID: {id}</p>
                        <p className="infoTag" id="resOrg' + num + '">Org: {org}</p>
                    </div>
                    
                )
            )
        );
    }

    const logoutClicked = () => {
        setlogPopup(!logPopup)
    }

    async function handleLogOut(){
        clientMethods.logout();
        sessionStorage.clear();
        navigate('/login')
    }

    return (
        <div id="PickResidentPage">
            <link rel="stylesheet" href="/css/pages/PickResidents.css"/>
            <Navbar page='App' logoutClicked={logoutClicked} client={client}/>
            <LogoutWarning trigger={logPopup} setTrigger={setlogPopup} logout={handleLogOut} />
            
            <div id='pickResidentPageContainer'>
                <div id='searchBarContainer'>
                    <form id="searchForm">
                        <label id='searchLabel' for='searchTerm'>Search: </label>
                        <input type="text" id='searchTerm' placeholder="Resident Name/ Bed/ ID"></input>
                    </form>

                    <FaSearch class='icon' id="searchBtn" onClick={() => {
                        searchBtnClk(document.getElementById('searchTerm').value)
                    }} />
                </div>
                
                <div id="resultsContainer">
                    <p id="residentTag">Residents: </p>
                    <div id='residentResultContainer'>
                        <div id="lastContainer"></div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}


export default PickResidents;