import React from 'react'
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons/lib";

import HamburgerOptionsSidebar from "./HamburgerSideOpt";

function Navbar(props) {
  let navigate = useNavigate();
  const handleClick = () => {
    navigate('/login');
  }

  return (
    <div id="Navbarcontainer">
      <link rel="stylesheet" href="/css/components/Navbar.css"/>
      <IconContext.Provider value={{color:"f5f5f5"}}>
          <HamburgerOptionsSidebar page= {props.page} logoutClicked={props.logoutClicked} client={props.client}/>
      </IconContext.Provider>

      <div id="logoContainer">
          <img id='logo' src="/assets/ProvenCare_logo.png" width="75px" height="75px" alt="logo"/>
          <h2>Proven Care</h2>
      </div>

      {props.page === 'Landing' ? <button id="loginBtn" type="submit" onClick={handleClick}>Login</button> : ""}
      
    </div>
  )
}

export default Navbar;