import React from "react";

import Navbar from "../components/Navbar"

const LandingPage = () => {

    return(
    <div id="landingPageContainer">
        <link rel="stylesheet" href="/css/pages/LandingPage.css"/>
        <Navbar page='Landing'/>

        <div id="landingPage_pageContainer">
            <img id='logo2' src="/assets/ProvenCare_branding.png" alt="logo"/>
            <div id="writingContainer">
                <h2 className='info' id="info_nursing">Nursing homes, assisted living, memory care, home health care… and more!</h2>
                <div id="infoTop">
                    <div className="infoSection" id="section_family">
                        <h3 className='infoTitle' id="title_family">Better for Families</h3>
                        <p className='info_body' id="body_family">Keeping contact with your loved one
                            <br/>Tracking their well-being & changes
                            <br/>Involving them in family matters
                            <br/>A bit less guilt…</p>
                    </div>
                    <div className="infoSection" id="section_resident">
                        <h3 className='infoTitle' id="title_resident">Better for Residents</h3>
                        <p className='info_body' id="body_resident">Your family is looking out for your interests
                            <br/>You remain connected to those who love you
                            <br/>Virtual visits and family events</p>
                    </div>
                </div>

                <div id="infoBot">
                    <div className="infoSection" id="section_facility">
                        <h3 className='infoTitle' id="title_facility">Better for Nursing Facilities</h3>
                        <p className='info_body' id="body_facility">An attractive feature for new residents
                            <br/>An expansion of services that can be offered
                            <br/>Staff retention features
                            <br/>Management features</p>
                    </div>
        
                    <div className="infoSection" id="section_staff">
                        <h3 className='infoTitle' id="title_staff">Better for Nursing Staff</h3>
                        <p className='info_body' id="body_staff">Promotion potential at all training/certification levels
                            <br/>Bonuses for special services delivered
                            <br/>Another training/credential for the future</p>
                    </div>
                </div>
            </div>
        </div>

        <p id="endMsg">Conceived and developed by EMS providers, computer professionals and those who have had to place family members in nursing homes.</p>
    </div>
    );
}

export default LandingPage;