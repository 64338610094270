import React from "react";


import { GrCircleInformation } from "react-icons/gr";
import { MdHowToVote } from "react-icons/md";
import { FaComment } from "react-icons/fa";
import { FaTools } from "react-icons/fa";


export const landingSideOptions = [
    {
        title: 'About Proven Care',
        path: '/aboutprovencare',
        icon: <GrCircleInformation/>,
        cName: 'nav-text'
    },

    {
        title: 'Nominate',
        path: '/nominate',
        icon: <MdHowToVote />,
        cName: 'nav-text'
    },

    {
        title:'Contact Us',
        path:'/',
        icon: <FaComment/>,
        cName: 'nav-text'
    },

    {
        title: 'About Founders',
        path:'/',
        icon: <FaTools/>,
        cName: 'nav-text'
    }
]

