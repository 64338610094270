import React, { Fragment } from "react";

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

import {LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import PickResidents from "./pages/PickResidentsPage";
import ResidentPage from "./pages/ResidentPage";
import ResidentMessages from "./pages/ResidentMessagesPage";
import Nominate from "./pages/NominatePage";
import AboutProven from "./pages/AboutProvenPage";


const baseURL = "https://demo.proven.care/Application/provencare?";
let currentCmd= "https://demo.proven.care/Application/provencare?";

const references = {
  resetCmd: () => {
    currentCmd = baseURL;
  },

  addParameter: (left, right, last = false) => {
    if(!last)
        currentCmd += left + "=" + right + "&"
    else
        currentCmd += left + "=" + right
  },

  issueCmd: async () => {
    const resp = await fetch(currentCmd)
    const data = await resp.text();
    return data;
  },

  resFromString: (resp) => {
    let obj = JSON.parse(resp)
    currRes.fName = obj.FirstName
    currRes.fullName = obj.FirstName + " "
    if(obj.NickName != null){
      currRes.nName = obj.NickName
      currRes.fullName += "'" + obj.NickName + "' "
    }
    currRes.lName = obj.LastName
    currRes.fullName += obj.LastName
    currRes.sNote = obj.ShortNote
    currRes.lNote = obj.LongNotes
    currRes.bedName = obj.BedName
    currRes.dob = obj.DOB
    currRes.admitDate = obj.AdmissionDate
    currRes.gender = obj.Gender

    currRes.ID = obj.PatientId
    currRes.bedID = obj.BedId
    currRes.orgID = obj.OrdId
    currRes.contacts = obj.relations
  },

  actionsFromString: (resp) => {
    let obj = JSON.parse(resp)
    currRes.pastActions = obj;
  },

  resFromSession: (resp) => {
    let obj = JSON.parse(resp)
    currRes.fName = obj.fName
    currRes.fullName = obj.fullName
    currRes.nName = obj.nName
    currRes.lName = obj.lName
    currRes.sNote = obj.sNote
    currRes.lNote = obj.lNote
    currRes.bedName = obj.bedName
    currRes.dob = obj.dob
    currRes.admitDate = obj.admitDate
    currRes.gender = obj.gender

    currRes.ID = obj.ID
    currRes.bedID = obj.bedID
    currRes.orgID = obj.orgID
    currRes.contacts = obj.contacts
    currRes.pastActions = obj.pastActions;
  }, 

  sanitizeInput: (str) => {
    return str.replaceAll("&", "%26")
  }
}

const client = {
  ticket: "null",
  resCount: -1,
  currResId: -1,
  availRes: "",
  isAdmin: false
}

const clientMethods = {
  getCurrentCmd(){
    return currentCmd;
  },

  sendLogin: async (user, pass) => {
      references.resetCmd()
      references.addParameter("cmd","login");
      references.addParameter("J", "1");
      references.addParameter("userid", references.sanitizeInput(user));
      references.addParameter("password", references.sanitizeInput(pass), true);
      const data = await references.issueCmd()
      return data
  },

  setInfo: (data) => {
    let obj = JSON.parse(data);
    client.ticket = obj.ticket;
    client.resCount = obj.patientcount;
    if(obj.patientcount === 1)
      client.currResId = obj.patients[0].PatientId
    client.availRes = JSON.stringify(obj.patients)
    client.isAdmin = obj.admin
  },

  setInfoFromClient: (data) => {
    let obj = JSON.parse(data);
    client.ticket = obj.ticket;
    client.resCount = obj.resCount;
    client.currResId = obj.currResId;
    client.availRes = obj.availRes;
    client.isAdmin = obj.isAdmin;
  },

  residentSearch: async (srch) => {
    references.resetCmd();
    references.addParameter("ticket", client.ticket);
    references.addParameter("cmd", "patientsearch");
    references.addParameter("search", references.sanitizeInput(srch), true);

    const data = await references.issueCmd()
    return data
  },

  getPatientInfo: async (resid) => {
    references.resetCmd()
    references.addParameter("ticket", client.ticket)
    references.addParameter("cmd", "getpatient");
    references.addParameter("id", ""+ resid,true);

    const data = await references.issueCmd()
    return data
  },

  getPatientPastInteraction: async (resid) => {
    references.resetCmd()
    references.addParameter("ticket", client.ticket)
    references.addParameter('cmd', 'getpatientlog');
    references.addParameter('patient', "" + resid, true);

    const data = await references.issueCmd()
    return data
  },

  getPatientPicture: (resid) => {
    references.resetCmd()
    references.addParameter("ticket", client.ticket)
    references.addParameter('cmd', 'picture');
    references.addParameter('patient', "" + resid, true);

    return currentCmd;
  },

  markoutorin: async (id, status, ret, ioro) => {
    references.resetCmd()
    references.addParameter("ticket", client.ticket)
    references.addParameter("cmd", "markoutorin")
    references.addParameter("patient", references.sanitizeInput(id))
    references.addParameter("status", references.sanitizeInput(status))
    references.addParameter("returnable", references.sanitizeInput(ret))
    references.addParameter("inorout", references.sanitizeInput(ioro), true)
    console.log(currentCmd)
  },

  logout: async () => {
    references.resetCmd()
    references.addParameter("ticket", client.ticket)
    references.addParameter("cmd", "logout", true);

    const data = await references.issueCmd()
    return data
  }
}

const currRes = {
  fName: '',
  lName: '',
  nName: '',
  fullname: '',
  sNote: '',
  lNote: '',
  bedName: '',
  dob: '',
  admitDate: '',
  gender: '',

  ID: -1,
  bedID: -1,
  score: -1,
  orgID: -1,

  contacts: [],
  conditions: [],
  pastActions: [],

  resFromString: (resp) => {
    references.resFromString(resp)
  },

  resFromSession: (resp) => {
    references.resFromSession(resp)
  },

  actionsFromString: (resp) => {
    references.actionsFromString(resp)
  }
}

export const appContext = React.createContext();

function App() {
  return (
    <HttpsRedirect>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <appContext.Provider value={{client, clientMethods, currRes}}>
          <link rel="stylesheet" href="/css/App.css"/>
          <Router>
            <Fragment>
              <Routes>
                <Route exact path='/' element={<LandingPage />}/>
                <Route exact path='/nominate' element={<Nominate />}/>
                <Route exact path='/aboutprovencare' element={<AboutProven />}/>
                <Route exact path='/login' element={<LoginPage />} />
                <Route exact path='/pick' element={<PickResidents />} />
                <Route exact path='/user' element={<ResidentPage />} />
                <Route exact path='/user/messages' element={<ResidentMessages />} />
              </Routes>
            </Fragment>
          </Router>
        </appContext.Provider>
      </LocalizationProvider>
    </HttpsRedirect>
  );
}

export default App;
