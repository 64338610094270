import React from 'react'
import Navbar from '../components/Navbar'

function Nominate() {
  return (
    <div id='NominatePage'>
        <link rel="stylesheet" href="/css/pages/NominatePage.css"/>
        <Navbar page='Login' />
        <div id='NominatePageContainer'>
            <h2 id='nominateHeader'>Nominate a Facility!</h2>
            <hr/>
            <div id='facilityFormContainer'>
                <h3 id='facilityHeader'>Facility Information</h3>
                    <input id='facilityNameTxt' className='fullLineTxt' placeholder='Facility Name'/>
                    <select id='slctType'>
                        <option value="" disabled selected>Select Type of Facility</option>
                        <option value='AssistedLiving'>Assisted Living</option>
                        <option value='daycare'>Day Care</option>
                        <option value='NursingHome'>Nursing Home</option>
                        <option value='Memory Unity'>Memory Unit</option>
                    </select>
                    <input id='facilityAddressTxt' className='fullLineTxt' placeholder='Address'/>
                    <div id='addressLine'>
                        <input id='facilityCityTxt' className='thirdLineTxt' placeholder='City'/>
                        <input id='facilityStateTxt' className='thirdLineTxt' placeholder='State'/>
                        <input id='facilityZipcodeTxt' className='thirdLineTxt' placeholder='Zipcode'/>
                    </div>
            </div>
            <hr/>
            <div id='personalFormContainer'>
                <h3 id='personalHeader'>Personal Information</h3>
                <input id='personalNameTxt' className='fullLineTxt' placeholder='Name'/>
                <div id='emailPhoneLineCont'>
                    <input id='personalEmailTxt' className='halfLineTxt' placeholder='Email'/>
                    <input id='personalPhoneTxt' className='halfLineTxt' placeholder='Phone'/>
                </div>

                <input id='personalRelationTxt' className='fullLineTxt' placeholder='Relation to Facility'/>
                <textarea id='facilityReason' placeholder='Why this Facility? (minimum of 25 words)' style={{resize:'none'}} />
            </div>
            <button id='submitForm'>Submit</button>
        </div>
        <div id='endTxtCont'>
            <p id='endTxt'>Selected facilities will be granted a 10-year license of Proven Care software including all features, updates and support. The facility will receive one handheld device and full access to cloud based server in order to effectively use the system. The facility must have reasonable WIFI and internet access in order to take full advantage of Proven Care software. The facility may at its own discretion charge for use of the service to its residents and family members of residents without royalty or fees to Proven Care LLC for the 10-year period. After the free use period, the system will be available at Proven Care’s best price at that time.
            Should the handheld device be damaged or lost, the customer facility will be responsible for repairs or replacement.</p>
        </div>
    </div>
  )
}

export default Nominate