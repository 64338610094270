import React, {useState} from 'react'
import Navbar from '../components/Navbar'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PromotionImage from '../components/promotionImage';

function AboutProven() {
    const [isHovering, setIsHovering] = useState(false);
    const [isHovering2, setIsHovering2] = useState(false);
    const [isHovering3, setIsHovering3] = useState(false);

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

    const promoData = [
        {
            img: "https://online.maryville.edu/wp-content/uploads/sites/97/2019/12/nursing-home-care-1.jpg",
            desc: "Outside Time",
            time: "8:15 PM"
        },
        {
            img: "https://www.usnews.com/object/image/00000147-1183-de24-af57-79fb936a0000/140707-seniorsexercise-stock.jpg?update-time=1501085675305&size=responsive640",
            desc: "Group Stretch",
            time: "2:57 PM"
        },
        {
            img: "https://baptistretirement.org/wp-content/uploads/2023/10/At-What-Age-Do-Most-People-Enter-Assisted-Living-1-1024x683.jpg",
            desc: "Morning Tea",
            time: "8:23 AM"
        },
        {
            img: "https://www.asb.de/application/files/2216/9693/0592/Pflege.jpg",
            desc: "Book Club Meeting",
            time: "3:00 PM"
        },
        {
            img: "https://747395.fs1.hubspotusercontent-na1.net/hub/747395/hubfs/How%20to%20Find%20the%20Right%20Assisted%20Senior%20Living%20in%20Greenville%20SC.jpg?length=930&name=How%20to%20Find%20the%20Right%20Assisted%20Senior%20Living%20in%20Greenville%20SC.jpg",
            desc: "Movie Night",
            time: "7:00 PM"
        },
        {
            img: "https://media.istockphoto.com/id/1413582310/photo/senior-man-eating-lunch-at-nursing-home.jpg?s=612x612&w=0&k=20&c=GEH8FOzll-DMdl_T-548TkhtqjatBoCgMYJQdUKpHI4=",
            desc: "Supper (ate 75%)",
            time: "12:04 PM"
        },
        {
            img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRETVd3Ud4uG7hZa3gnRgcQnIyHig5AEqfnvA&s",
            desc: "Sleeping",
            time: "3:10 AM"
        },
        {
            img: "https://uwnewsroom-prod-backend.parallelpublicworks.com/sites/default/files/dialysis_0.jpg",
            desc: "Dialysis Appointment",
            time: "8:15 PM"
        }
    ]

    return (
    <div id='AboutProvenCont'>
        <link rel="stylesheet" href="/css/pages/AboutProven.css"/>
        <Navbar page='Login'/>
        
        <div id='headerContiner'>
            <h2>About Proven Care</h2>
            <div id='imageCarouselContainer'>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                // ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                // keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={1000}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                id='imageCar'
            >
                {promoData.map((item, index) => {
                    return (
                    <PromotionImage img={item.img} desc={item.desc} time={item.time} index={index} />
                        // <promotionImage />
                )
                })}
            </Carousel>
            </div>
        </div>

        <div id='topinfoCont'>
            <div id='topInfo-leftCont'>
                <h3 id='topinfoHeader'>What does Proven Care Improve?</h3>
                <p id='topinfo'>At the moment, there are only 3 feasible options to provide suitable care for individuals living in assisted living, nursing homes, memory care and/or adult day care. Those options are: </p>
            </div>
            
            <div id='topInfo-rightCont'>
                <div id='firstRightInfo' className='rightInfo' onMouseEnter={() => {setIsHovering(true)}} onMouseLeave={() => {setIsHovering(false)}}>
                    <h3>1. Care in Home</h3>
                </div>
                <div id='secondRightInfo' className='rightInfo'onMouseEnter={() => {setIsHovering2(true)}} onMouseLeave={() => {setIsHovering2(false)}}>
                    <h3>2. Private</h3>
                </div>
                <div id='thirdRightInfo' className='rightInfo'onMouseEnter={() => {setIsHovering3(true)}} onMouseLeave={() => {setIsHovering3(false)}}>
                    <h3>3. Group Facilities</h3>
                </div>
            </div>
        </div>
        
        <div id='topinfo-bottomCont'>
            {isHovering && <div id='firstRightInfoEx' className='rightInfoEx'>
                <p>Most families do not have the means to provide the proper care that is required for individuals in these circumstances due to the level of expertise needed and the complexity of the medical issues.</p>
            </div>}
            {isHovering2 && <div id='secondRightInfoEx' className='rightInfoEx'>
                <p>Even around-the-clock nurses and aides are often faced with high turnover, missed shifts, and the need to provide home resources and transportation to medical facilities when necessary</p>
            </div>}
            {isHovering3 && <div id='thirdRightInfoEx' className='rightInfoEx'>
                <p>Traditional nursing homes, memory care facilities and assisted living facilities provide a viable option for many families and their loved ones. However, with the busy lives of family members and geographic distance, it is often difficult to visit loved ones regularly and assess whether the full benefits of skilled nursing or memory care are being received.</p>
            </div>}
        </div>

        <div id='questionsCont'>
            <div id='quest_leftCont'>
                <div id='quest_leftFirst' className='quest_cont'>
                    <div id='quest_leftfirstheaderCont' className='quest_header'>
                        <img className='quoteMark' src="/assets/quotation-mark.svg" alt="quotation"/>
                        <h3 id='questleft_header' className='quest_headertxt'>What is the product and how does it work?</h3>
                    </div>
                        <p id='questleft_ans' className='quest_ans'>Proven Care consists of a handheld device used by staff members and a secure server with information about subscribed residents.  The staff member can record any interaction with the resident in under 15 seconds.  That information is stored on the server and is available to those authorized to view resident activity.  Those authorized may include family members and others who have an interest in knowing how the resident is doing.  The facility may choose between a cloud-based server through the internet, or an on-site dedicated server.
                            Individuals may log on to the Proven Care web site anytime to see the history of resident activities and interactions with the staff.  Meanwhile, nursing staff receive reminders to check on those for whom Proven Care has been selected. Staff can send quick notes to families and residents may request that a note be sent to their loved ones.  Return messages from families may be given to residents in written form or read aloud by the nursing staff.  Even photos may be emailed to residents and printed at the facility.
                            </p>
                </div>
                
                <div id='quest_leftSec' className='quest_cont'>
                <div id='quest_leftsecheaderCont' className='quest_header'>
                    <img className='quoteMark' src="/assets/quotation-mark.svg" alt="quotation"/>
                    <h3 id='questrightthird_header' className='quest_headertxt'>Are HIPAA and other privacy laws a problem?</h3>
                </div>
                    <p id='questrightthird_ans' className='quest_ans'>The system is built with the best practices regarding privacy and security. However, we realize that revealing resident status over the internet to potentially multiple family members could provide an exposure for privacy leaks.  For that reason, Proven Care residents (or their legal proxies) must sign a privacy release prior to being monitored by the Proven Care system. </p>
                </div>
            </div>
            <div id='quest_rightCont'>
                <div id='quest_rightFirst' className='quest_cont'>
                    <div id='questrightfirst_headercont' className='quest_header'>
                        <img className='quoteMark' src="/assets/quotation-mark.svg" alt="quotation"/>
                        <h3 id='questrightfirst_header' className='quest_headertxt'>What does Proven Care Do?</h3>
                    </div>
                    <p id='questrightfirst_ans' className='quest_ans'>Proven Care is an option for families and residents of nursing homes, memory units, and assisted care facilities. The Proven Care system tracks staff/resident interactions, maintains recurring and one-time scheduled actions, reminds staff when a resident has not received attention for some time, and provides history for staff and authorized members of the resident’s family. 
Proven Care provides an enhanced experience at facilities where it is offered and chosen by families. Family members will be able to speak with loved ones, exchange messages, conduct virtual visits, and access care records. Families will be able to access regular reports of staff interaction with their loved ones as well as subjective observations by staff members. How well has the resident been eating?  How is his/her mood during the day?  Has the resident been taking part in group activities?  

Consider this a step above standard nursing and memory care. A facility may consider this an elite level of care for those who would like to pay a reasonable fee for easier access to loved ones and their records.  
Beyond just family involvement, Proven Care provides guidance and prioritization for routine and custom activities. Scheduled tasks, such as custom cognitive tests, can track the onset of mental decline. Changes in activity involvement, eating and sleeping may indicate a treatable condition that would otherwise be missed.
</p>
                </div>
                
                <div id='quest_rightSec' className='quest_cont'>
                    <div className='quest_header'>
                        <img className='quoteMark' src="/assets/quotation-mark.svg" alt="quotation"/>
                        <h3 id='questrightsec_header' className='quest_headertxt'>Isn't this more work for the facility staff?</h3>
                    </div>
                    <p id='questrightsec_ans' className='quest_ans'>The tool is very easy to use and the additional time is minimal.   When a staff member is assigned to residents with the elite status, a bump in compensation may follow.  The result is an opportunity to retain highly valued staff members. </p>
                </div>

            </div>
            <div id='quest_bottomCont' className='quest_cont'>
                <div className='quest_header'>
                    <img className='quoteMark' src="/assets/quotation-mark.svg" alt="quotation"/>
                    <h3 id='questbottom_header' className='quest_headertxt'>Is that all there is to it?</h3>
                </div>
                <p id='questbottom_ans' className='quest_ans'>Not really. There are numerous other features within the software that benefit residents, families, facilities and staff. These include an enhanced ability to interface with doctors, physical and occupational therapists, dieticians and EMS as needed.</p>
            </div>
        </div>
    </div>
    );
}

export default AboutProven;