import React, {useState, useContext} from "react";
import { useNavigate } from "react-router-dom";

import { appContext } from "../App";

import MarkInOrOut from "../components/MarkInOrOut";
import Navbar from "../components/Navbar";
import LogoutWarning from "../components/LogoutWarning";

const ResidentPage = () =>{
    const navigate = useNavigate();
    const [showMark, setShowMark] = useState(false);
    const [logPopup, setlogPopup] = useState(false);
    const client = useContext(appContext).client;
    const clientMethods = useContext(appContext).clientMethods;
    const currRes = useContext(appContext).currRes;

    if(client.ticket === 'null')
        clientMethods.setInfoFromClient(sessionStorage.getItem("client"));
    
    if(currRes.ID === -1)
        currRes.resFromSession(sessionStorage.getItem("currRes"));


    const handleMarkSubmit = () => {
        setShowMark(false);
    }

    const logoutClicked = () => {
        setlogPopup(!logPopup)
    }

    async function handleLogOut(){
        clientMethods.logout();
        sessionStorage.clear();
        navigate('/login')
    }

    return (
    <div id = 'ResidentPage'>
        <link rel="stylesheet" href="/css/pages/ResidentPage.css"/>
        <Navbar page="App" logoutClicked={logoutClicked} client={client}/>
        <div id = 'ResidentPageContainer'>
            <LogoutWarning trigger={logPopup} setTrigger={setlogPopup} logout={handleLogOut} />
            <MarkInOrOut trigger={showMark} setTrigger={setShowMark} submitVal={handleMarkSubmit} />
            
            <div id = 'resPage_info'>
                <div id = 'resPage_info_top'>
                    <div id = 'resPage_picture'>
                        <img src={clientMethods.getPatientPicture(client.currResId)} alt= 'logo' id='respage_pic'/>
                        <button id="btnMarkInorOut" onClick={() => {setShowMark(true)}}>Mark In/Out</button>
                    </div>
                    <div id = 'resPage_info-top_inf'>
                        <div id = 'info_top_half'>
                            <div id = 'resPage_name'>
                                <p className='value' id="value_name">{currRes.fullName}</p>
                                <div id='name_line'></div>
                                <p>Name</p>
                            </div>
                        </div>
                        <div id = 'info_bottom_half'>
                            <div id = 'resPage_bed'>
                                <p className='value' id="value_bedName">{currRes.bedName == null ? "Empty" : currRes.bedName}</p>
                                    <div id='name_line'></div>
                                    <p>Bed Name</p>
                            </div>
                            <div id = 'resPage_id'>
                                <p className='value' id="value_id">{currRes.ID}</p>
                                    <div id='name_line'></div>
                                    <p>ID</p>
                            </div>
                        </div>
                    </div>
                    <div className="break" />
                    <div id = 'resPage_buttons'>
                        <button id='msgBtn' className='resPageBtn' onClick={() => {
                            navigate('messages')
                            }}>Messages</button>
                        <button id='scheduleBtn' className="resPageBtn">Schedule</button>
                    </div>
                </div>
                <div id = 'resPage_info_bottom'>
                    <div id = 'bottom_info'>
                        <p className='value' id="value_DOB">{currRes.dob}</p>
                        <div id='name_line'></div>
                        <p>DOB</p>
                    </div>
                    
                    <div id = 'bottom_info'>
                        <p className='value' id="value_gender">{currRes.gender === 'M' ? 'Male' : 'Female'}</p>
                        <div id='name_line'></div>
                        <p>Gender</p>
                    </div>

                    <div id = 'bottom_info'>
                        <p className='value' id="value_admitDate">{currRes.admitDate}</p>
                        <div id='name_line'></div>
                        <p>Admit Date</p>
                    </div>
                </div>
            </div>
            
            <div id = 'divider'></div>

            <div className = 'resPage_interactions'>
                <h3>Last Interactions:</h3>
                <div id='interactionsContainer'>
                    {currRes.pastActions.map((item, index) => {
                        return (<div className="interaction" id={"interaction" + currRes.ID}>
                            <p className="interaction_action">{item.PatientActionName}</p>
                            <p className="interaction_date">{item.PatientActionDate}</p>
                        </div>
                    );})}
                    {currRes.pastActions.length === 0 ? <h3 id="noActionsWarn">No Past Actions Found</h3> : ""}
                    {currRes.pastActions.length > 3 ? <button id='interaction_more'>More</button> : ""}
                </div>
            
            </div>
        
            <div id = 'divider'></div>

            <div className='Notes'>
                <h3>Notes:</h3>
                <div id='notesContainer'>
                    <div id='notesBox'>
                        <p id="res_note">{currRes.sNote}</p>
                    </div>
                </div>
            </div>

            <div id = 'divider'></div>

            <div className='contacts'>
                <h3>Contacts:</h3>
                <div id='contactsContainer'>
                    {currRes.contacts.length === 0 ? <h3 id="noContWarn">No Contacts Found</h3> : ""}
                    {currRes.contacts.map((item, index) => {
                        return(
                        <div className="contact" id={"contact"+index}>
                            <h3 className="contactName">{item.FirstName + " " + item.LastName}</h3>
                            <h4 className="contactRelation">{item.RelationType}</h4>
                            <p className="contactEmail">{"Email: " + item.EmailAddress}</p>
                            <p className="contactPhone">{"Phone: " + item.Phone1}</p>
                            <p className="contactNotes">{"Notes: " + item.RelationNotes}</p>
                        </div>
                        ) 
                    })}
                    {currRes.contacts.length > 3 ? <button id='contacts_more'>More</button> : ""}
                </div>
            </div>

            
            <div id = 'divider'></div>
            
            <div id='genReportContainer'>
                <button className='generateReport'>Generate Report</button>
            </div>
        </div>
    </div>
    );
}

export default ResidentPage;