import React from "react";
import { RiLogoutBoxLine } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { MdAdminPanelSettings } from "react-icons/md";
import { IoSettingsSharp } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";

export const appSideOptions = [
    {
        title: 'Logout',
        path: '',
        icon: <RiLogoutBoxLine/>,
        cName: 'nav-text'
    },

    {
        title: 'Pick Residents',
        path: '/pick',
        icon: <FaUsers/>,
        cName: 'nav-text'
    },

    {
        title: 'Resident Page',
        path: '/user',
        icon: <FaUser />,
        cName: 'nav-text'
    },

    {
        title:'Settings',
        path:'/settings',
        icon: <IoSettingsSharp/>,
        cName: 'nav-text'
    },

    {
        title: 'Admin',
        path:'/admin',
        icon: <MdAdminPanelSettings/>,
        cName: 'nav-text'
    }
]